import { action, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

export default class Report {
  constructor() {
    this.data = [];

    makeObservable(this, {
      data: observable,
      fetch: flow,
      profitLoss: flow,
      profit: flow,
      deposit: flow,
      invoice: flow,
      occupant: flow,
      ledger: flow,
      balance: flow,
      setData: action,
    });
  }

  setData() {
    this.data = [];
  }

  *fetch(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/transaction?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      // console.log(response)
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }
  *profitLoss(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/profitLoss?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      // console.log(response)
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *profit(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/profit?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *deposit(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/deposit?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *invoice(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/invoice?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *occupant(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/occupant?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *ledger(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/ledger?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  *balance(startDate, endDate, propertyId) {
    try {
      const response = yield api.get(`/report/balance?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`);
      this.data = response.data;
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }
}