import { action, computed, flow, makeObservable, observable } from 'mobx';
import api from '../utils/apiService';

import { apiFetcher } from '../utils/fetch';
import { updateItems } from './utils';

export default class Room {
  constructor() {
    this.selected = {};
    this.filters = { searchText: '', status: ["vacant", "booked", "available_soon", "occupied"], propertyId: "" };
    this.items = [];

    makeObservable(this, {
      selected: observable,
      filters: observable,
      items: observable,
      filteredItems: computed,
      setSelected: action,
      setFilters: action,
      fetch: flow,
      fetchOne: flow,
      create: flow,
      createMany: flow,
      update: flow,
      delete: flow,
    });
  }

  get filteredItems() {
    let filteredItems = this.items;
    if (this.filters.status?.length) {

      const statusFilters = this.filters.status.filter((status) =>
        ['vacant', 'occupied', 'booked', 'available_soon'].includes(status)
      );
      if (statusFilters.length) {
        filteredItems = filteredItems.filter(({ status }) =>
          statusFilters.includes(status)
        );
      }
    }
    if (this.filters.searchText) {
      const regExp = /\s|\.|-/gi;
      const cleanedSearchText = this.filters.searchText
        .toLowerCase()
        .replace(regExp, '');

      filteredItems = filteredItems.filter(
        ({ name }) =>
          name.replace(regExp, '').toLowerCase().indexOf(cleanedSearchText) !=
          -1
      );
    }

    if (this.filters.propertyId) {
      // filteredItems = filteredItems.filter(({ items }) =>
      //   this.filters.propertyId.includes(items)
      // )
      filteredItems = filteredItems.filter(({ propertyId }) => 
        this.filters.propertyId == propertyId
      )
    }
    return filteredItems;
  }

  setSelected = (room) => (this.selected = room);

  setFilters = ({ searchText = '', status = [], propertyId = "" }) =>
    (this.filters = { searchText, status, propertyId });

  *fetch(params = {}) {
    try {
      const queryString = Object.keys(params)
        .map(key => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
        
      const response = yield api.get(`/rooms?${queryString}`);

      this.items = response.data;
      if (this.selected?._id) {
        this.setSelected(
          this.items.find((item) => item._id === this.selected._id) || {}
        );
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *fetchOne(roomId) {
    try {
      const response = yield api.get(`/rooms/${roomId}`);
      const updatedRoom = response.data;
      this.items = updateItems(updatedRoom, this.items);
      if (this.selected?._id === updatedRoom._id) {
        this.selected = updatedRoom;
      }
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *create(room) {
    try {
      const response = yield api.post('/rooms', room);
      const createdRoom = response.data;
      this.items = updateItems(createdRoom, this.items);

      return { status: 200, data: createdRoom };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *createMany(rooms) {
    try {
      const response = yield api.post('/rooms/create_many', rooms);
      const createdRoom = response.data;
      this.items = updateItems(createdRoom, this.items);

      return { status: 200, data: createdRoom }
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *update(room) {
    try {
      const response = yield api.patch(
        `/rooms/${room._id}`,
        room
      );
      const updatedRoom = response.data;
      this.items = updateItems(updatedRoom, this.items);
      if (this.selected?._id === updatedRoom._id) {
        this.setSelected(updatedRoom);
      }
      return { status: 200, data: updatedRoom };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }

  *delete(ids) {
    try {
      yield api.delete(`/rooms/${ids.join(',')}`);
      return { status: 200 };
    } catch (error) {
      return { status: error?.response?.status };
    }
  }
}
